<template>
  <div>
    <b-row>
      <b-col md="5">
        <div class="card ">
          <div class="card-body" style="margin: -10px;">
            <b-row>
              <b-col md="5">
                <b-avatar variant="light-info" size="50">
                  <b-avatar variant="info"> <feather-icon icon="GridIcon" size='20' /></b-avatar>
                </b-avatar>
              </b-col>
              <b-col md="7">
                <div>
                  <h5 class="font-weight-bolder mb-0 text-primary">{{ kontrak.jm_usulan }}</h5>
                  <p class="card-text">Paket <br>Pekerjaan</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <!-- <b-col md="5">
        <div class="card ">
          <div class="card-body" style="margin: -10px;">
            <b-row>
              <b-col md="4">
                <b-avatar variant="light-warning" size="50">
                  <b-avatar variant="warning"> <feather-icon icon="GridIcon" size='20' /></b-avatar>
                </b-avatar>
              </b-col>
              <b-col md="8">
                <div>
                  <h5 class="font-weight-bolder mb-0 text-primary">{{ kontrak.belum_kontrak }}</h5>
                  <p class="card-text">Belum Kontrak</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col> -->
      <b-col md="5">
        <div class="card ">
          <div class="card-body" style="margin: -10px;">
            <b-row>
              <b-col md="5">
                <b-avatar variant="light-primary" size="50">
                  <b-avatar variant="primary"> <feather-icon icon="GridIcon" size='20' /></b-avatar>
                </b-avatar>
              </b-col>
              <b-col md="7">
                <div>
                  <h5 class="font-weight-bolder mb-0 text-primary">{{ kontrak.jm_kontrak }}</h5>
                  <p class="card-text">Kontrak Paket Pekerjaan</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="2">
        <b-avatar rounded="" variant="light-primary" size="82" v-b-modal.modal-1>
          <b-avatar rounded="" variant="primary" size="50"> <feather-icon icon="PlusIcon" size='40' /></b-avatar>
        </b-avatar>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BBadge, BAvatar, BButton
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BBadge, BAvatar, BButton,
  },
  props: {
    kontrak: {}
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>